import {createStore} from "vuex";
import VuexPersistence from 'vuex-persist'

import axios from "axios";
import config from "../config"

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = createStore({
    state() {
        return {
            // system
            readOnly: false,
            maintenance: false,
            settings: {},
            countries: [],
            reloadPages: {},
            forceLogout: false,

            // user
            user: {},
            token: '',
            fcmToken: '',

            // reading
            isScanner: false,
            gasValueEdited: false,
            scannerFailed: false,
            podNumber: '',
            serialNumber: '',
            pointMeter: {},
            meterImage: '',
            gasValue: '',
            gasSubValue: '',
            chekker: '',
            readingDate: '',

            // news detail
            newsDetailTitle: '',
            newsDetailText: ''
        }
    },
    getters: {
        readOnly(state) {
            return state.readOnly
        },
        maintenance(state) {
            return state.maintenance
        },
        settings(state) {
            return state.settings
        },
        countries(state) {
            return state.countries
        },
        reloadPages(state) {
            return state.reloadPages
        },
        forceLogout(state) {
            return state.forceLogout
        },

        user(state) {
            return state.user
        },
        token(state) {
            return state.token
        },
        fcmToken(state) {
            return state.fcmToken
        },
        isLogged(state) {
            return state.token.length > 0
        },

        isScanner(state) {
            return state.isScanner
        },
        gasValueEdited(state) {
            return state.gasValueEdited
        },
        scannerFailed(state) {
            return state.scannerFailed
        },
        podNumber(state) {
            return state.podNumber
        },
        serialNumber(state) {
            return state.serialNumber
        },
        pointMeter(state) {
            return state.pointMeter
        },
        meterImage(state) {
            return state.meterImage
        },
        gasValue(state) {
            return state.gasValue
        },
        gasSubValue(state) {
            return state.gasSubValue
        },
        chekker(state) {
            return state.chekker
        },
        readingDate(state) {
            return state.readingDate
        },

        newsDetailTitle(state) {
            return state.newsDetailTitle
        },
        newsDetailText(state) {
            return state.newsDetailText
        }
    },
    mutations: {
        setReadOnly(state, payload) {
            state.readOnly = payload
        },
        setMaintenance(state, payload) {
            state.maintenance = payload
        },
        setSettings(state, payload) {
            state.settings = payload
        },
        setCountries(state, payload) {
            state.countries = payload
        },
        setReloadPages(state, payload) {
            state.reloadPages = payload
        },
        setForceLogout(state, payload) {
            state.forceLogout = payload
        },

        setUser(state, payload) {
            state.user = payload
        },
        setToken(state, payload) {
            state.token = payload
        },
        setFcmToken(state, payload) {
            state.fcmToken = payload
        },

        setScanner(state, payload) {
            state.isScanner = payload
        },
        setGasValueEdited(state, payload) {
            state.gasValueEdited = payload
        },
        setScannerFailed(state, payload) {
            state.scannerFailed = payload
        },
        setPodNumber(state, payload) {
            state.podNumber = payload
        },
        setSerialNumber(state, payload) {
            state.serialNumber = payload
        },
        setPointMeter(state, payload) {
            state.pointMeter = payload
        },
        setMeterImage(state, payload) {
            state.meterImage = payload
        },
        setGasValue(state, payload) {
            state.gasValue = payload
        },
        setGasSubValue(state, payload) {
            state.gasSubValue = payload
        },
        setChekker(state, payload) {
            state.chekker = payload
        },
        setReadingDate(state, payload) {
            state.readingDate = payload
        },

        setNewsDetailTitle(state, payload) {
            state.newsDetailTitle = payload
        },
        setNewsDetailText(state, payload) {
            state.newsDetailText = payload
        }
    },
    actions: {
        readOnly({commit}, payload) {
            commit('setReadOnly', payload)
        },
        maintenance({commit}, payload) {
            commit('setMaintenance', payload)
        },
        settings({commit}) {

            axios.get(config.API_URL + '/settings', config.JS_HEADERS)
                .then((response) => {
                    console.warn('settings data', response)
                    commit('setSettings', response.data.data)
                }, (err) => {
                    console.warn('settings error', err)
                })

        },
        countries({commit, state}) {
            if (state.countries.length > 0) return false

            axios.get(config.API_URL + '/country', config.JS_HEADERS)
                .then((response) => {
                    console.warn('countries data', response)
                    commit('setCountries', response.data.data)
                }, (err) => {
                    console.warn('countries error', err)
                })
        },
        reloadPages({commit, state}, payload) {
            let pages = state.reloadPages

            if (payload.value) {
                pages[payload.key] = payload.value
            } else {
                delete pages[payload.key]
            }
            commit('setReloadPages', pages)
        },

        user({commit}, payload) {
            commit('setUser', payload)
        },
        token({commit}, payload) {
            commit('setToken', payload)
        },
        fcmToken({commit}, payload) {
            commit('setFcmToken', payload)
        },

        isScanner({commit}, payload) {
            const body = document.body;
            if (!payload) {
                body.classList.remove('scanner-opened')
            } else {
                body.classList.add('scanner-opened')
            }
            commit('setScanner', payload)
        },
        gasValueEdited({commit}, payload) {
            commit('setGasValueEdited', payload)
        },
        scannerFailed({commit}, payload) {
            commit('setScannerFailed', payload)
        },
        podNumber({commit}, payload) {
            commit('setPodNumber', payload)
        },
        serialNumber({commit}, payload) {
            commit('setSerialNumber', payload)
        },
        pointMeter({commit}, payload) {
            commit('setPointMeter', payload)
        },
        meterImage({commit}, payload) {
            commit('setMeterImage', payload)
        },
        gasValue({commit}, payload) {
            commit('setGasValue', payload)
        },
        gasSubValue({commit}, payload) {
            commit('setGasSubValue', payload)
        },
        chekker({commit}, payload) {
            commit('setChekker', payload)
        },
        readingDate({commit}, payload) {
            commit('setReadingDate', payload)
        },

        // eslint-disable-next-line no-unused-vars
        addPoint({commit}, payload) {
            return axios.post(config.API_URL + '/point', payload, config.JS_HEADERS)
                .then((response) => {
                    // console.warn('add point', response)
                    return response
                }, (err) => {
                    console.warn('add point error', err)
                    return Promise.reject(err);
                })
        },
        // eslint-disable-next-line no-unused-vars
        verifyDeduction({commit}, payload) {
            const body = {
                value: payload.gasValue
            }
            return axios.post(`${config.API_URL}/point/${payload.pod}/meter/${payload.serial}/deduction/verify`, body, config.JS_HEADERS)
                .then((response) => {
                    // console.warn('deduction verify', response)
                    return response
                }, (err) => {
                    console.warn('deduction verify error', err)
                    return Promise.reject(err);
                })
        },
        // eslint-disable-next-line no-unused-vars
        createDeduction({commit, state}, payload) {
            const body = {
                type: payload.type,
                // serial_number: payload.serial,
                value: payload.value,
                image: state.meterImage
            }

            if (payload.chekker) {
                body['chekker'] = payload.chekker
            }

            if (payload.created_at) {
                body['created_at'] = payload.created_at
            }

            return axios.post(`${config.API_URL}/point/${payload.pod}/meter/${payload.serial}/deduction`, body, config.JS_HEADERS)
                .then((response) => {
                    // console.warn('deduction create', response)
                    return response
                }, (err) => {
                    console.warn('deduction create error', err)
                    return Promise.reject(err);
                })
        },

        clearDeductionData({commit}) {
            commit('setScannerFailed', false)
            commit('setPodNumber', '')
            commit('setSerialNumber', '')
            commit('setGasValue', '')
            commit('setGasSubValue', '')
            commit('setChekker', '')
            commit('setPointMeter', {})
            commit('setMeterImage', '')
            commit('setGasValueEdited', false)
        },

        newsDetailTitle({commit}, payload) {
            commit('setNewsDetailTitle', payload)
        },
        newsDetailText({commit}, payload) {
            commit('setNewsDetailText', payload)
        }
    },
    plugins: [vuexLocal.plugin]
})
export default store