<template>
	<ion-menu content-id="main-content" type="overlay" side="end" :swipe-gesture="false">
		<ion-content>
			
			<div class="main-menu-header">
				<div class="close-menu" @click="$root.closeMenu">
					<img src="../../../public/assets/icon/arrow_left.svg" alt="">
					<div class="text">{{ $t('menu.close') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="btn-notifications" :class="{'extended': $root.notificationsBadge > 0}" @click="$root.toPage({name: 'PageNotifications'})">
					<div class="icon-wrap">
						<img src="../../../public/assets/icon/bell_yellow.svg" alt="">
					</div>
					<div v-if="$root.notificationsBadge > 0" class="notification-badge">{{ $root.notificationsBadge }}</div>
				</div>
			</div>
			
			<div class="main-menu-buttons">
				
				<div class="button-wrap bg-gradient" @click="$root.toPage({name: 'PagePOD'})">
					<div class="icon">
						<div ref="lottieScanMenu" class="lottie-scan"></div>
					</div>
					<div class="title">{{ $t('selfValueReading') }}</div>
				</div>
				
				<div class="button-wrap" @click="$root.toPage({name: 'PagePointsList'})">
					<div class="icon">
						<img src="../../../public/assets/icon/house.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.points') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toPage({name: 'PageNews'})">
					<div class="icon">
						<img src="../../../public/assets/icon/news.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.news') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toPage({name: 'PageRequestsList'})">
					<div class="icon">
						<img src="../../../public/assets/icon/requests_yellow.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.requests') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toModal('customerLineModal')">
					<div class="icon">
						<img src="../../../public/assets/icon/support.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.customerLine') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toModal('reportLineModal')">
					<div class="icon">
						<img src="../../../public/assets/icon/report_phone.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.reportLine') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toPage({name: 'PageRepairs'})">
					<div class="icon">
						<img src="../../../public/assets/icon/repairs.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.repairs') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toModal('reportModal')">
					<div class="icon">
						<img src="../../../public/assets/icon/report.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.report') }}</div>
				</div>
				
				<div v-if="$root.isApp" class="button-wrap" @click="$root.toPage({name: 'PageRecommended'})">
					<div class="icon">
						<img src="../../../public/assets/icon/recommended.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.recommended') }}</div>
				</div>
				
				<div class="button-wrap" @click="$root.toPage({name: 'PageProfile'})">
					<div class="icon">
						<img src="../../../public/assets/icon/profile.svg" alt="">
					</div>
					<div class="title">{{ $t('navigationButton.profile') }}</div>
				</div>
			
			</div>
			
			<div class="main-menu-footer">
				<div class="logout" @click="$root.logout">{{ $t('menu.logout') }}<img src="../../../public/assets/icon/logout.svg" alt=""></div>
				
				<div class="version">{{ $t('menu.version') + $root.version }}</div>
			</div>
		
		</ion-content>
	</ion-menu>
</template>

<script>
import {IonContent, IonMenu} from "@ionic/vue";

import lottie from "lottie-web";
import lottieScan from "../../../public/assets/lottie/sppd_scan_icon.json";

export default {
	components: {
		IonContent, IonMenu
	},
	methods: {
		startAnimation() {
			const scanMenu = this.$refs.lottieScanMenu
			
			lottie.loadAnimation({
				container: scanMenu,
				loop: true,
				autoplay: true,
				animationData: lottieScan
			})
		},
	},
	mounted() {
		this.startAnimation()
	}
}
</script>

<style lang="scss" scoped>
ion-menu {
	--width: 350px;
	
	&::part(backdrop) {
		opacity: .5 !important;
	}
	
	@container app (max-width: 340px) {
		&::part(container) {
			--width: 320px;
		}
	}
	@container app (max-width: 319px) {
		&::part(container) {
			--width: 280px;
		}
	}
}

ion-menu::v-deep(ion-content) {
	--background: var(--ion-color-background);
	--padding-start: 20px;
	--padding-end: 20px;
	//--padding-top: 20px;
	//--padding-bottom: 20px;
	
	.main-menu-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 20px;
		
		.close-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			
			img {
				width: 20px;
				height: auto;
				aspect-ratio: 1;
				margin-right: 10px;
			}
			
			.text {
				font-size: 13.5px;
			}
		}
	}
	
	.main-menu-buttons {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: calc((15px/2) * -1);
		
		.button-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: rgba(0, 0, 0, 0.05) 0 10px 20px;
			border-radius: 8px 8px 16px 8px;
			padding: 20px 10px;
			margin: calc(15px/2);
			flex-basis: calc(50% - 15px);
			
			&:not(.bg-gradient) {
				background: white;
			}
			
			.icon {
				margin-bottom: 5px;
				
				.lottie-scan {
					width: 35px;
					height: auto;
					aspect-ratio: 1;
				}
				
				img {
					width: 35px;
					height: auto;
					aspect-ratio: 1;
				}
			}
			
			.title {
				font-size: 13.5px;
				text-align: center;
			}
		}
	}
	
	.main-menu-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		font-size: 12px;
		margin-top: 40px;
		margin-bottom: 40px;
		
		.logout {
			color: var(--ion-color-text-gray);
			display: flex;
			flex-direction: row;
			align-items: center;
			
			img {
				margin-left: 10px;
			}
		}
		.version {
			color: var(--ion-color-text-gray);
			opacity: .5;
		}
	}
}
</style>