<template>
	<ion-app>
		<ion-split-pane content-id="main-content" :when="false">
			<SideBarMenu />
			<ion-router-outlet id="main-content"></ion-router-outlet>
			
			<div v-if="!online" class="network-status">
				<div class="icon">
					<ion-icon :icon="cloudOfflineOutline"></ion-icon>
				</div>
				{{ $t('networkOffline') }}
			</div>
		</ion-split-pane>
	</ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet, IonSplitPane, IonIcon, isPlatform, menuController, useIonRouter} from '@ionic/vue';
// import {IonApp, IonRouterOutlet, IonSplitPane, IonIcon, menuController, useIonRouter} from '@ionic/vue';
import {cloudOfflineOutline} from 'ionicons/icons';

import { PushNotifications } from "@capacitor/push-notifications"

import SideBarMenu from "@/components/general/SideBarMenu.vue";

export default {
	name: 'App',
	components: {
		SideBarMenu,
		IonApp,
		IonRouterOutlet,
		IonSplitPane,
		IonIcon
	},
	setup() {
		return {
			ionRouter: useIonRouter(),
			isApp: isPlatform('capacitor')
			// isApp: true
		}
	},
	data() {
		return {
			cloudOfflineOutline,
			
			menuController,
			
			reportModal: false,
			reportLineModal: false,
			customerLineModal: false,
			
			online: true,
			recaptchaSiteKey: '',
			notificationsBadge: 0,
			version: '0.3.8'
		}
	},
	methods: {
		getNotificationsBadge() {
			
			this.$axios.get(`${this.config.API_URL}/customer/notification/count`, this.config.JS_HEADERS)
				.then((res) => {
					console.warn('response notifications badge', res)
					
					if (res?.data?.type === 'success') {
						this.notificationsBadge = res.data.data.count
					} else {
						// this.serverError = res.data.message
					}
					
				}, (err) => {
					console.warn(err)
					// this.serverError = err.data.message
				})
		},
		
		async initPushNotifications() {
			// let self = this;
			
			console.warn('firebase push start');
			
			await PushNotifications.addListener('registration', token => {
				console.info('Registration token: ', token.value);
				this.$store.dispatch('fcmToken', token.value);
			});
			
			await PushNotifications.addListener('registrationError', err => {
				console.error('Registration error: ', err.error);
			});
			
			await PushNotifications.addListener('pushNotificationReceived', notification => {
				console.log('Push notification received: ', notification);
			});
			
			await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
				console.log('Push notification action performed', notification);
				
				if (notification.actionId !== 'tap') return;
				
				const openUrl = notification.notification.data.open_url
				const isLogged = this.$store.getters.isLogged
				
				if (openUrl) {
					if (isLogged) {
						this.$router.push({path: openUrl})
					} else {
						this.$router.replace({name: 'PageLogin'})
					}
				}
			});
			
			let permStatus = await PushNotifications.checkPermissions();
			
			if (permStatus.receive === 'prompt') {
				permStatus = await PushNotifications.requestPermissions();
			}
			
			if (permStatus.receive !== 'granted') {
				throw new Error('User denied permissions!');
			}
			
			await PushNotifications.register();
			
			
			console.warn('firebase push end');
			
		},
		
		closeMenu() {
			menuController.close()
		},
		toPage(params) {
			this.redirect(params)
			this.closeMenu()
		},
		toModal(modal) {
			this.closeMenu()
			this.$root[modal] = true
		},
		logout() {
			this.$axios.patch(`${this.config.API_URL}/logout`, this.config.JS_HEADERS)
				.then((res) => {
					console.warn('logout', res)
					
					if (res.data.type === 'success') {
						this.$store.dispatch('token', '')
						this.$store.dispatch('user', {})
						this.closeMenu()
						this.ionRouter.navigate({name: 'PageIntro'}, 'root', 'push')
						this.$store.commit('setForceLogout', false)
					} else {
						// this.serverError = res.data.message
						this.$store.dispatch('token', '')
						this.$store.dispatch('user', {})
						this.closeMenu()
						this.ionRouter.navigate({name: 'PageIntro'}, 'root', 'push')
						this.$store.commit('setForceLogout', false)
					}
					
				}, (err) => {
					console.warn(err)
					// this.serverError = err.data.message
					this.$store.dispatch('token', '')
					this.$store.dispatch('user', {})
					this.closeMenu()
					this.ionRouter.navigate({name: 'PageIntro'}, 'root', 'push')
					this.$store.commit('setForceLogout', false)
				})
		},
		
		async imageToBase64(image) {
			return new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.onloadend = () => resolve(reader.result)
					reader.onerror = reject
					reader.readAsDataURL(image)
				})
		},
		
		addWebStyle() {
			let style = document.createElement('style')
			style.innerHTML = "#sppd-moapp ion-app {max-width: 900px; margin: 0 auto; box-shadow: 0 0 10px #ccc;}"
			document.head.appendChild(style)
		},
		
		async webLoginFromGluuToken() {

            this.$cookies.set('wordpress_logged_in_3d101a8fb708316bc48a1d40f8f05b7f', 'internal-user-sso%7C1684503372%7CKGbLofK38RBVHctilSOkXdToSZqV880xzenqC7Fc0ED%7C6568ffb5a5c03acf938ad8f7c4d642986633be51e7dd760159760f0d9008acbe', new Date(2024, 1, 1))
            
            const cookies = this.$cookies.keys()
            console.log(cookies)
			
			let cookieKey = ''
			cookies.forEach(key => {
                if (key.includes('wordpress_logged_in')) {
                    cookieKey = key
				}
			})
			
			let cookieToken = this.$cookies.get(cookieKey)
            console.log(cookieKey, cookieToken)
			
            // return false;
            
            const body = {
                "token" : cookieToken,
                "version" : this.version,
            }

            this.$axios.post(this.config.API_URL + '/login', body, this.config.JS_HEADERS)
                .then((res) => {
                    console.warn(res)

                    if (res.data.type === 'success') {
                        this.$store.dispatch('token', res.data.access_token)
                        this.$store.dispatch('user', res.data.data)
                        this.ionRouter.navigate({name: 'PageHome'}, 'root', 'push')
                    } else {
                        this.serverError = res.data.message
                    }
                    
                }, (err) => {
                    console.warn(err)
                    this.serverError = err.data.message
                })
                .finally(() => {
                    this.loading = false
                })
		},

        webAssignApi() {
            return new Promise((resolve) => {
                console.log(window.location.hostname)

                if (window.location.hostname === 'www-test.spp-distribucia.sk') {
                    this.config.API_URL = this.config.DEV_API_URL
                    resolve()

                } else if (window.location.hostname === 'spp-distribucia.sk') {
                    this.config.API_URL = this.config.PROD_API_URL
                    resolve()
                } else {
                    resolve()
                }

            })

        },
		
		
	},
	// computed: {
	// 	needLogout: {
	// 		get() {
	// 			const need = this.forceLogout
	//
	// 			console.warn('logout', need)
	// 			if (need === true) {
	// 				this.logout()
	//
	// 				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
	// 				this.forceLogout = false
	// 			}
	//
	// 			return need
	// 		}
	// 	}
	// },
	watch: {
		'$store.getters.forceLogout': {
			handler: function(val) {
				console.warn('force logout', val)
				if (val) {
					this.logout()
				}
			},
			immediate: true
		}
	},
	mounted() {
		// console.log('mounted logout', this.forceLogout)
		// if (this.forceLogout === true) {
		// 	this.logout()
		// 	this.forceLogout = false
		// }
		
		// // get global app settings
		// this.$store.dispatch('settings')
		// // clear deduction data
		// if (this.isApp) {
		// 	this.$store.dispatch('clearDeductionData')
		// }
	},
	async created() {
		let self = this
		// set environment for debug
		if (this.config.DEBUG) {
			this.isApp = true
		}
		
		if (!this.isApp) {
			// add web only styles
			this.addWebStyle()
			
			await this.webAssignApi()
			
			// await this.webLoginFromGluuToken()
			
			//TODO uncomment reCaptcha key for production
			// this.recaptchaSiteKey = "6LeKO6MlAAAAALFqU0m_BNo2MidL2vCq6DxeJQAZ"
			
			// dev key for testing
			this.recaptchaSiteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
		}
		
		// get global app settings
		await this.$store.dispatch('settings')
		// clear deduction data
		if (this.isApp) {
			await this.$store.dispatch('clearDeductionData')
		}
		
		// init push notifications
		document.addEventListener('deviceready', function () {
			self.initPushNotifications();
		})
		
		// init network status bar
		this.online = window.navigator.onLine
		
		window.addEventListener('offline', function() {
			console.log('offline');
			self.online = false
		});
		
		window.addEventListener('online', function() {
			console.log('online');
			self.online = true
			
			window.location.reload()
		});
	}
}
</script>

<style lang="scss" scoped>
.network-status {
	position: fixed;
	bottom: env(safe-area-inset-bottom);
	left: 0;
	width: 100vw;
	width: 100cqw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px;
	background: rgba(255, 0, 0, .8);
	color: white;
	text-align: center;
	//font-size: 16px;
	//font-weight: 600;
	font-size: 14px;
	font-weight: 400;
	
	.icon {
		position: relative;
		display: flex;
		margin-left: 10px;
		margin-right: 5px;
		
		//&:before {
		//	content: "";
		//	position: absolute;
		//	top: 45%;
		//	left: 45%;
		//	width: 20px;
		//	height: 2px;
		//	transform: translate(-50%, -50%) rotate(45deg);
		//	background: white;
		//}
		
		ion-icon {
			width: 25px;
			height: auto;
			aspect-ratio: 1;
		}
	}
}
</style>
