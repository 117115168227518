import { createRouter, createWebHistory } from '@ionic/vue-router';
import {isPlatform} from '@ionic/vue'
import store from '../store'
import config from '../config'

const routes = [
  {
    path: '',
    redirect: config.ROUTE_PREFIX + '/intro'
  },
  {
    path: config.ROUTE_PREFIX + '/intro',
    name: 'PageIntro',
    meta: {public: true, web: true},
    component: () => import ('../views/PageIntro.vue')
  },
  // auth
  {
    path: config.ROUTE_PREFIX + '/login',
    name: 'PageLogin',
    meta: {public: true, web: true},
    component: () => import ('../views/auth/PageLogin.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/register',
    name: 'PageRegister',
    meta: {public: true, web: true},
    component: () => import ('../views/auth/PageRegister.vue')
  },
  // dashboard
  {
    path: config.ROUTE_PREFIX + '/home',
    name: 'PageHome',
    meta: {public: false, web: true},
    component: () => import ('../views/PageHome.vue')
  },
  // user
  {
    path: config.ROUTE_PREFIX + '/user/notifications',
    name: 'PageNotifications',
    meta: {public: false},
    component: () => import ('../views/user/PageNotifications.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/user/profile',
    name: 'PageProfile',
    meta: {public: false, web: true},
    component: () => import ('../views/user/PageProfile.vue')
  },
  // news
  {
    path: config.ROUTE_PREFIX + '/news',
    name: 'PageNews',
    meta: {public: false},
    component: () => import ('../views/news/PageNews.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/news/detail',
    name: 'PageNewsDetail',
    meta: {public: false},
    component: () => import ('../views/news/PageNewsDetail.vue')
  },
  // reading
  {
    path: config.ROUTE_PREFIX + '/reading/pod',
    name: 'PagePOD',
    meta: {web: true},
    component: () => import ('../views/reading/PagePOD.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/reading/serial',
    name: 'PageSerial',
    meta: {web: true},
    component: () => import ('../views/reading/PageSerial.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/reading/gas',
    name: 'PageGasValue',
    meta: {web: true},
    component: () => import ('../views/reading/PageGasValue.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/scan/serial',
    name: 'PageScanSerial',
    meta: {web: true},
    component: () => import ('../views/scan/PageScanSerial.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/scan/gas',
    name: 'PageScanGas',
    meta: {web: true},
    component: () => import ('../views/scan/PageScanGas.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/reading/chekker',
    name: 'PageChekker',
    meta: {web: true},
    component: () => import ('../views/reading/PageChekker.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/reading/gas-confirm',
    name: 'PageGasValueConfirm',
    meta: {web: true},
    component: () => import ('../views/reading/PageGasValueConfirm.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/reading/not-required',
    name: 'PageReadingNotRequired',
    meta: {web: true},
    component: () => import ('../views/reading/PageReadingNotRequired.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/reading/thank-you',
    name: 'PageReadingThankYou',
    meta: {web: true},
    component: () => import ('../views/reading/PageThankYou.vue')
  },
  // points
  {
    path: config.ROUTE_PREFIX + '/points',
    name: 'PagePointsList',
    meta: {web: true},
    component: () => import ('../views/points/PagePointsList.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/point/reading-history',
    name: 'PagePointReadingHistory',
    meta: {web: true},
    component: () => import ('../views/points/PagePointReadingHistory.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/point/history',
    name: 'PagePointHistory',
    meta: {web: true},
    component: () => import ('../views/points/PagePointHistory.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/point/add',
    name: 'PagePointAdd',
    meta: {web: true},
    component: () => import ('../views/points/PagePointAdd.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/point/thank-you',
    name: 'PagePointThankYou',
    meta: {web: true},
    component: () => import ('../views/points/PagePointThankYou.vue')
  },
  // requests
  {
    path: config.ROUTE_PREFIX + '/requests',
    name: 'PageRequestsList',
    meta: {public: false},
    component: () => import ('../views/request/PageRequestsList.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/request/connect',
    name: 'PageConnectRequest',
    meta: {public: false},
    component: () => import ('../views/request/PageConnectRequest.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/request/first-connect',
    name: 'PageFirstConnectRequest',
    meta: {public: false},
    component: () => import ('../views/request/PageFirstConnectRequest.vue')
  },
  // report
  {
    path: config.ROUTE_PREFIX + '/report',
    name: 'PageReport',
    meta: {public: false},
    component: () => import ('../views/general/PageReport.vue')
  },
  {
    path: config.ROUTE_PREFIX + '/report/thank-you',
    name: 'PageReportThankYou',
    meta: {public: false},
    component: () => import ('../views/general/PageReportThankYou.vue')
  },
  // recommended
  {
    path: config.ROUTE_PREFIX + '/recommended',
    name: 'PageRecommended',
    meta: {public: false},
    component: () => import ('../views/general/PageRecommended.vue')
  },
  // repairs
  {
    path: config.ROUTE_PREFIX + '/repairs',
    name: 'PageRepairs',
    meta: {public: false},
    component: () => import ('../views/general/PageRepairs.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

  const isApp = config.DEBUG ? true : isPlatform('capacitor')

  console.log('before each route', from, to)

  console.log('is web:', !isApp, ', web allowed:', to.meta.web)
  if (!isApp && !to.meta.web && store.getters.isLogged) {
    next('/home')
  } else if (!isApp && !to.meta.web && !store.getters.isLogged) {
    next('/intro')
  }

  console.log('logged:', store.getters.isLogged, ', public:', to.meta.public);
  if (store.getters.isLogged && to.meta.public) {
    next('/home')
  } else if (!store.getters.isLogged && to.meta.public === false) {
    next('/intro')
  } else {
    next()
  }
})

export default router
