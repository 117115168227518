export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                redirect(location) {
                    this.$router.push(location)
                },
                rootRedirect(location) {
                    this.ionRouter.navigate(location, 'root', 'push')
                },

                redirectNews(title, text, location) {
                    this.$store.dispatch('newsDetailTitle', title)
                    this.$store.dispatch('newsDetailText', text)
                    this.redirect(location)
                },

                redirectReadingDone() {
                    // clear data
                    this.$store.dispatch('clearDeductionData')
                    // redirect
                    if (this.$store.getters.isLogged) {
                        // reload point list
                        this.$store.dispatch('reloadPages', {key: 'PagePointsList', value: true})

                        this.ionRouter.navigate({name: 'PagePointsList'}, 'root', 'push')
                    } else {
                        this.ionRouter.navigate({name: 'PageIntro'}, 'root', 'push')
                    }
                },

                podValidation(value) {
                    const numLength = this.$store.getters.settings.config?.['point.pod.numbers']
                    const prefix = this.$store.getters.settings.config?.['point.pod.prefix']

                    // console.warn(charLength, numLength)
                    // (SKSPPDIS)+([0-9]{12})
                    return new RegExp(`(${prefix})+([0-9]{${numLength}})`, "g").test(value)
                },
            }

        })
    }
}