import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from "./store";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/style.scss';

import global from './global'

import {createI18n} from "vue-i18n";

import { messages } from './lang';
const i18n = createI18n({
  locale: 'sk',
  fallbackLocale: 'en',
  messages,
})

import VueCookies from 'vue-cookies'

// create vue app
const app = createApp(App)
  .use(IonicVue)
  .use(i18n)
  .use(global)
  .use(router)
  .use(store)
  .use(VueCookies);

// sentry.io logger
import * as Sentry from '@sentry/vue'
Sentry.init({
  app,
  dsn: "https://406c196aadf2429b863673303a4f4b84@o554246.ingest.sentry.io/4505031073923072",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "sppd.wame.sk", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// inject app config
import config from './config'
app.config.globalProperties.config = config

// logout from axios interceptor helper
app.config.globalProperties.forceLogout = false

import axios from 'axios'

axios.interceptors.request.use((request) => {
  // set Bearer token if user is logged in
  if (store.getters.isLogged) {
    request.headers['Authorization'] = `Bearer ${store.getters.token}`;
  }
  return request
})
axios.interceptors.response.use((response) => {
  // console.log('success', response)

  // turn off read-only or maintenance if api is OK
  if ((response.data.type === 'success' || response.data.type === 'error') &&
      (store.getters.readOnly === true || store.getters.maintenance === true)) {
    store.commit('setReadOnly', false);
    store.commit('setMaintenance', false);
  }
  // return response anyway to .then function
  return response;

}, (error) => {
  console.log('error', error)

    // force logout
  if (error.response?.status === 401 && !store.getters.forceLogout) {
    // app.config.globalProperties.forceLogout = true
    store.commit('setForceLogout', true);

    // set read-only
  } else if (error.response?.data?.type === 'readonly') {
    store.commit('setReadOnly', true);

    // set maintenance
  } else if (error.response?.data?.type === 'maintenance') {
    store.commit('setMaintenance', true);

    // else return error to .catch
  } else {
    return Promise.reject(error);
  }
})

app.config.globalProperties.$axios = axios

import moment from 'moment'
app.config.globalProperties.$moment = moment

router.isReady().then(() => {
  app.mount('#sppd-moapp');
});