const config =  {
    DEBUG: false,

    ROUTE_PREFIX: process.env.VUE_APP_ISAPP ? '' : '/e-sluzby/samoodpocet-zemneho-plynu',

    // API_URL: "https://sppd.wame.sk/api/v1",
    API_URL: "https://moapbe-test.spp-distribucia.sk/api/v1",
    // API_URL: "https://sppd/api/v1",

    DEV_API_URL: "https://moapbe-test.spp-distribucia.sk/api/v1",
    PROD_API_URL: "https://moapbe.spp-distribucia.sk/api/v1",


    JS_HEADERS: {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    },
    FD_HEADERS: {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        }
    }
}; export default config